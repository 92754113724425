// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  background: '#F2F4F3',
  primary: '#004B58', // teal
  secondary: '#D7DF23', // lime
  black: '#000000',
  darkTeal: '#064954',
  grey: '#8F8F8F',
  light: '#FCFCFC',
  noise: {
    onBackground: '#FCFCFC'
  }
};

const textColors = {
  text: base.black,
  heading: base.black,
  background: base.background
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
