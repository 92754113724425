module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","languages":["en","de"],"locales":{"default":"de","translations":[{"pathPrefix":"en","hreflang":"en"}]},"translatedPaths":[{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"},{"default":"sustainability","de":"nachhaltigkeit"},{"default":"contact","de":"kontakt"},{"default":"process","de":"prozess"}],"secondPath":null,"redirect":false,"wrapProvider":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://the-puzzlers.matomo.cloud/","siteUrl":"https://www.mdl-module.com","disableCookies":true,"respectDnt":false},
    },{
      plugin: require('../node_modules/gatsby-source-keepitsimple/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"mdl-module"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
