import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ArrowDown = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 37 119"
      fill="none"
      sx={{ width: '100%', ...sx }}
      {...props}>
      <path
        d="M12.0426 18.0723C8.51026 12.4863 5.26546 4.76451 0.336653 -1.47156e-08C-2.33312 6.07886 11.714 22.2207 12.9873 33.3927C18.3679 50.2738 20.9555 67.8121 22.6395 84.4879C23.8717 92.1276 21.5305 101.533 22.7217 108.228C23.5431 104.86 23.2967 107.201 24.1592 108.393C26.3361 107.53 27.5272 107.325 27.4862 104.532C28.5951 105.435 28.2255 96.5635 28.7184 93.442C28.2255 90.1972 28.5541 86.788 28.1844 83.4611C27.938 85.4737 27.8969 89.7043 27.7737 85.1452C27.3629 82.7218 27.5272 80.3395 26.7879 78.0394C27.0343 85.638 26.0897 77.8341 25.1861 75.3697C24.4878 76.8483 25.5146 78.943 25.2271 80.9556C24.3646 80.7502 25.145 78.5733 24.3235 77.0536L23.7074 76.684C25.6378 75.9857 22.6395 72.6999 24.57 71.8784C24.0771 73.2749 25.5968 73.6035 25.145 72.0427C25.5968 70.7283 24.2003 69.7426 24.9396 68.7979C25.8021 71.632 26.1718 73.3571 25.5968 69.3319C24.8575 54.6687 19.8465 34.7892 12.0426 18.0723ZM21.3662 65.9638C21.5305 62.0618 19.1483 56.6402 19.6822 53.4775C21.4073 63.2941 23.1324 73.1928 24.3646 82.8861C24.1181 95.701 22.5163 70.2354 21.3662 65.9638Z"
        fill="currentColor"
      />
      <path
        d="M20.0947 117.881C27.2825 119.606 30.7327 109.173 35.0454 108.27C38.742 106.052 31.9238 104.409 30.322 106.134C27.6933 106.586 22.1073 116.074 22.3126 112.747C13.3176 83.7899 11.2639 99.8907 20.0947 117.881Z"
        fill="currentColor"
      />
    </Box>
  );
};
