import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisBusinessContactData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisBusinessContact {
        nodes {
          entry {
            address {
              html
            }
            contact_links {
              link_text
              link_to
            }
            social_links {
              link_text
              link_to
            }
          }
          translations {
            en {
              address {
                html
              }
              contact_links {
                link_text
                link_to
              }
              social_links {
                link_to
                link_text
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
