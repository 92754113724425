import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisInspirationPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisInspirationPage {
        nodes {
          entry {
            header {
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            image_gallery {
              description {
                html
              }
              image_five {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_four {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_one {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_seven {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_six {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_three {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_two {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            project {
              headline {
                html
              }
              text_block
            }
            support {
              headline {
                html
              }
              text_block_one
              text_block_two
            }
            urban_dev {
              description {
                html
              }
              headline {
                html
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          translations {
            en {
              header {
                image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              image_gallery {
                description {
                  html
                }
                image_five {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                image_four {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                image_one {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                image_seven {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                image_six {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                image_three {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                image_two {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              project {
                headline {
                  html
                }
                text_block
              }
              support {
                headline {
                  html
                }
                text_block_one
                text_block_two
              }
              urban_dev {
                description {
                  html
                }
                headline {
                  html
                }
                image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return useKisSectionData(data);
};
