import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const UnderlineForNavLink = ({
  isPathMatch,
  isSmall,
  color = 'secondary',
  className
}) => {
  return (
    <Box
      className={`underline-for-nav-link ${className}`}
      as="span"
      sx={{
        position: 'absolute',
        width: '100%',
        height: isSmall ? '0.5rem' : '1.2rem',
        backgroundColor: color,
        bottom: 0,
        left: 0,
        transform: isPathMatch ? 'scaleX(1)' : 'scaleX(0)',
        transformOrigin: 'left center',
        transition: 'all 0.3s ease-in-out'
      }}
    />
  );
};
