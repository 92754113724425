import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisSustainabilityPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisSustainabilityPage {
        nodes {
          entry {
            benefits_of_timber {
              benefit_list {
                data {
                  description_text {
                    html
                  }
                  title {
                    html
                  }
                }
              }
              headline {
                html
              }
            }
            header {
              description {
                html
              }
            }
            impact {
              headline {
                html
              }
              text_block
            }
            life_cycle {
              caption {
                html
              }
              description
              headline {
                html
              }
              text_block_one
              text_block_two
            }
            production_cycle {
              description
              emission_headline {
                html
              }
              emission_textblock_one
              emission_textblock_two
              step_one_description
              headline {
                html
              }
              step_one_title
              step_three_description
              step_three_title
              step_two_description
              step_two_title
            }
          }
          translations {
            en {
              benefits_of_timber {
                benefit_list {
                  data {
                    description_text {
                      html
                    }
                    title {
                      html
                    }
                  }
                }
                headline {
                  html
                }
              }
              header {
                description {
                  html
                }
              }
              impact {
                headline {
                  html
                }
                text_block
              }
              life_cycle {
                caption {
                  html
                }
                description
                headline {
                  html
                }
                text_block_one
                text_block_two
              }
              production_cycle {
                description
                emission_headline {
                  html
                }
                emission_textblock_one
                emission_textblock_two
                headline {
                  html
                }
                step_one_description
                step_one_title
                step_three_description
                step_three_title
                step_two_description
                step_two_title
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
