import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisLandingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisLandingPagePage {
        nodes {
          entry {
            about {
              headline {
                html
              }
              link_label
              link_to
              text_block_one
              text_block_two
            }
            header {
              description {
                html
              }
            }
            modular {
              caption {
                html
              }
              description {
                html
              }
              link_label
              link_to
            }
            production {
              caption {
                html
              }
              description {
                html
              }
              employee_name {
                html
              }
              employee_position
            }
            stacked_module {
              headline {
                html
              }
            }
            timber {
              caption {
                html
              }
              link_label
              link_to
              description {
                html
              }
            }
            statistic {
              business_year_label
              business_year_number
              comparison_label
              comparison_number
              description {
                html
              }
              employees_label
              employees_number
              production_label
              production_number
            }
          }
          translations {
            en {
              about {
                headline {
                  html
                }
                link_label
                link_to
                text_block_one
                text_block_two
              }
              header {
                description {
                  html
                }
              }
              modular {
                caption {
                  html
                }
                description {
                  html
                }
                link_label
                link_to
              }
              production {
                caption {
                  html
                }
                description {
                  html
                }
                employee_name {
                  html
                }
                employee_position
              }
              stacked_module {
                headline {
                  html
                }
              }
              statistic {
                business_year_label
                business_year_number
                comparison_label
                comparison_number
                description {
                  html
                }
                employees_label
                employees_number
                production_number
                production_label
              }
              timber {
                caption {
                  html
                }
                description {
                  html
                }
                link_label
                link_to
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
