const transition = {
  type: 'tween',
  duration: 0.3,
  ease: 'easeInOut'
};

export const slideInAnimation = {
  initial: {
    opacity: 0,
    transition
  },
  animate: {
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0,
    transition
  }
};
