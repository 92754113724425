export const fontFamilies = {
  body: {
    normal: 'open-sans-normal-regular, sans-serif',
    bold: 'open-sans-normal-bold, sans-serif'
  },
  primary: {
    normal: 'velo-serif-text-normal-regular, serif',
    bold: 'velo-serif-text-normal-bold, serif',
    italic: 'velo-serif-text-normal-bold-italic, serif'
  },
  secondary: {
    normal: 'Black Stone, serif'
  }
};
