import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { UnderlineForNavLink } from 'components';

// Hooks
import { usePathMatch } from 'sections/Navigation/helper';

export const NavigationLinkItemWithIndicator = ({
  variant = 'navigation',
  link: { to, text },
  // this options bellow supposed to be used in navigation header which has header section with different background
  color,
  isHeaderYellow
}) => {
  const { isPathMatch } = usePathMatch(to);

  return (
    <NavigationLink
      key={to}
      to={to}
      variant={variant}
      sx={{
        color,
        position: 'relative',
        ':hover .underline-for-nav-link': {
          transform: 'scaleX(1)'
        }
      }}>
      <Box
        as="span"
        sx={{
          position: 'relative',
          zIndex: 1
        }}>
        {text}
      </Box>
      <UnderlineForNavLink
        className="navigation-bar__link_underline"
        isPathMatch={isPathMatch}
        color={isHeaderYellow ? 'noise.onBackground' : 'secondary'}
        isSmall
      />
    </NavigationLink>
  );
};
