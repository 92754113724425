import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisProcessPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisProcessPagePage {
        nodes {
          entry {
            header {
              description {
                html
              }
            }
            productivity {
              graph {
                construction_percentage {
                  html
                }
                construction_title {
                  html
                }
                headline
                manufacturing_percentage {
                  html
                }
                manufacturing_title {
                  html
                }
              }
              headline {
                html
              }
              text_blocks {
                text
              }
            }
            standards {
              headline {
                html
              }
              text_blocks {
                text
              }
            }
            steps {
              headline {
                html
              }
              process_steps {
                description {
                  html
                }
              }
            }
          }
          translations {
            en {
              header {
                description {
                  html
                }
              }
              productivity {
                graph {
                  construction_percentage {
                    html
                  }
                  construction_title {
                    html
                  }
                  headline
                  manufacturing_percentage {
                    html
                  }
                  manufacturing_title {
                    html
                  }
                }
                headline {
                  html
                }
                text_blocks {
                  text
                }
              }
              standards {
                headline {
                  html
                }
                text_blocks {
                  text
                }
              }
              steps {
                headline {
                  html
                }
                process_steps {
                  description {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
