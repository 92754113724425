import React from 'react';

// External Components
import { GridItem, Box } from '@thepuzzlers/pieces';

import bigNoise from 'assets/png/noise/big-noise-min.png';
import smallNoise from 'assets/png/noise/small-noise-min.png';
import mediumNoise from 'assets/png/noise/normal-noise-min.png';

// Grid Spacer - Usage: Inside sections/GridWrappers
export const GridSpacer = ({ bg, height, sx }) => {
  return <GridItem className="grid-spacer" sx={{ bg, height, ...sx }} />;
};

// Regular Spacer - Usage: In between sections
export const Spacer = ({ bg, height, sx, ...props }) => {
  return (
    <Box
      className="spacer"
      sx={{ bg, height, backgroundRepeat: 'repeat', ...sx }}
      {...props}
    />
  );
};

export const WhiteNoiseSpacer = ({ height, sx, ...props }) => (
  <Spacer
    height={height}
    bg="background"
    sx={{
      backgroundImage: `url(${bigNoise})`,
      ...sx
    }}
    {...props}
  />
);
export const TealNoiseSpacer = ({ height, sx, ...props }) => (
  <Spacer
    className="teal-noise-spacer"
    height={height}
    bg="primary"
    sx={{
      backgroundImage: `url(${smallNoise})`,
      ...sx
    }}
    {...props}
  />
);
export const LimeNoiseSpacer = ({ height, sx, ...props }) => (
  <Spacer
    height={height}
    bg="secondary"
    sx={{
      backgroundImage: `url(${mediumNoise})`,
      ...sx
    }}
    {...props}
  />
);
