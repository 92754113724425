import React from 'react';

// External Components
import { Button, Box } from '@thepuzzlers/pieces';

// Local Components
import { LinkScribble } from './LinkScribble';

export const ButtonPrimary = ({
  text,
  isDark,
  primary = false,
  scribbleColor = 'text',
  sx,
  ...props
}) => (
  <Box
    className="button-primary"
    sx={{
      position: 'relative',
      width: 'max-content',
      ...sx
    }}>
    <Button
      variant={
        primary ? 'links.hyperlink.primary' : 'links.hyperlink.secondary'
      }
      sx={{
        bg: isDark ? 'primary' : 'secondary',
        color: isDark ? 'light' : 'text'
      }}
      {...props}>
      {text}
    </Button>
    <LinkScribble color={scribbleColor} primary={primary} />
  </Box>
);
