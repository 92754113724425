import React from 'react';

// External Components
import { GridItem } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';

// Reused Components

export const GalleryRowWrapper = ({ children, sx, isLastRow, isSmallGap }) => (
  <GridItem
    className="members__row-wrapper"
    sx={{
      display: 'grid',
      gap: isSmallGap
        ? ['1.6rem', '1.8rem', '2.4rem', '0.8rem', '0.8rem', '1.6rem']
        : ['5.4rem', '7.2rem', 0, '0.8rem', '0.8rem', '1.6rem'],
      gridTemplateColumns: !isLastRow
        ? [
            '1fr',
            '1fr',
            '1fr',
            'repeat(3, 1fr)',
            'repeat(3, 1fr)',
            'repeat(3, 1fr)'
          ]
        : [
            '1fr',
            '1fr',
            '1fr',
            '28.2rem auto auto',
            '41.2rem auto auto',
            '52.4rem auto auto'
          ],
      height: !isLastRow
        ? [null, null, null, '24.5rem', '36.8rem', '47.2rem']
        : [null, null, null, '21.6rem', '31.3rem', '40rem'],
      ...sx
    }}>
    {children}
  </GridItem>
);

export const GalleryRowItem = ({
  sx,
  src,
  alt,
  isLarge,
  children,
  className,
  ...props
}) => {
  return (
    <ImageContainer
      className={className}
      src={src}
      alt={alt}
      sx={{
        width: isLarge
          ? '100%'
          : ['23.8rem', '29.8rem', '37.7rem', '100%', '100%', '100%'],
        gridColumnEnd: isLarge
          ? [null, null, null, 'span 2', 'span 2', 'span 2']
          : 'span 1',
        height: isLarge
          ? ['39.5rem', '64rem', '53rem', '100%', '100%', '100%']
          : ['26.9rem', '33.7rem', '42.6rem', '100%', '100%', '100%'],
        overflow: 'hidden',
        alignSelf: 'start',
        ...sx
      }}
      {...props}>
      {children}
    </ImageContainer>
  );
};
