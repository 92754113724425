const inputDefault = {
  borderColor: 'secondary',
  borderRadius: '0.4rem',
  borderStyle: 'solid',
  borderWidth: '0.1rem',
  color: 'text',
  fontFamily: 'primary.normal',
  fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.6rem', '1.8rem'],
  lineHeight: 1.5,
  mt: '1.6rem',
  outline: 'none',
  p: '1.2rem 1.6rem',
  '::placeholder': {
    color: 'grey'
  }
};

export const forms = {
  label: {
    color: 'background',
    fontFamily: 'primary.normal',
    fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.6rem', '1.8rem'],
    lineHeight: 1,
    m: 0
  },
  input: {
    ...inputDefault
  },
  textarea: {
    ...inputDefault
  },
  checkbox: {
    ...inputDefault,
    p: 0,
    mt: 0,
    border: 'none',
    color: 'secondary',
    'input:checked ~ &': {
      color: 'secondary'
    }
  }
};
