import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisAboutUsPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisAboutUsPage {
        nodes {
          entry {
            about {
              headline {
                html
              }
              text_block_one
              text_block_two
            }
            belief {
              headline {
                html
              }
            }
            header {
              description
            }
            members {
              business_head_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              business_head_name {
                html
              }
              business_head_position
              ceo_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              ceo_image_small {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              ceo_name {
                html
              }
              ceo_position
              description {
                html
              }
              estimation_head_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              estimation_head_name {
                html
              }
              estimation_head_position
              management_member_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              management_member_name {
                html
              }
              management_member_position
              project_head_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              project_head_name {
                html
              }
              project_head_position
              sales_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              sales_name {
                html
              }
              sales_position
              timber_expert_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              timber_expert_name {
                html
              }
              timber_expert_position
              workspace {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }

              sales_and_marketing_position
              sales_and_marketing_name {
                html
              }
              sales_and_marketing_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }

              accounting_head_position
              accounting_head_name {
                html
              }
              accounting_head_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }

              sales_director_position
              sales_director_name {
                html
              }
              sales_director_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              office_management_position
              office_management_name {
                html
              }
              office_management_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }

              construction_management_name {
                html
              }
              construction_management_position
              construction_management_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }

              architect_name {
                html
              }
              architect_position
              architect_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }

              architect_two_name {
                html
              }
              architect_two_position
              architect_two_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }

              procedurement_name {
                html
              }
              procedurement_position
              procedurement_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          translations {
            en {
              about {
                headline {
                  html
                }
                text_block_one
                text_block_two
              }
              belief {
                headline {
                  html
                }
              }
              header {
                description
              }
              members {
                business_head_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                business_head_name {
                  html
                }
                business_head_position
                ceo_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                ceo_image_small {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                ceo_name {
                  html
                }
                ceo_position
                description {
                  html
                }
                estimation_head_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                estimation_head_name {
                  html
                }
                estimation_head_position
                management_member_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                management_member_name {
                  html
                }
                management_member_position
                project_head_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                project_head_name {
                  html
                }
                project_head_position
                sales_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                sales_name {
                  html
                }
                sales_position
                timber_expert_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                timber_expert_name {
                  html
                }
                timber_expert_position
                workspace {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }

                sales_and_marketing_position
                sales_and_marketing_name {
                  html
                }
                sales_and_marketing_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }

                accounting_head_position
                accounting_head_name {
                  html
                }
                accounting_head_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }

                sales_director_position
                sales_director_name {
                  html
                }
                sales_director_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                office_management_position
                office_management_name {
                  html
                }
                office_management_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }

                construction_management_name {
                  html
                }
                construction_management_position
                construction_management_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }

                architect_name {
                  html
                }
                architect_position
                architect_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }

                architect_two_name {
                  html
                }
                architect_two_position
                architect_two_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }

                procedurement_name {
                  html
                }
                procedurement_position
                procedurement_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
