import React from 'react';
// External Components
import { Heading } from '@thepuzzlers/pieces';

import { colors } from 'gatsby-plugin-theme-ui/colors';

// Assets
export const MarkedHeading = ({ data, as, sx, underlineSx, ...props }) => {
  // convert span to be mark
  const htmlData = data.replace(/span/g, 'mark');
  return (
    <Heading
      as={as}
      dangerouslySetInnerHTML={{
        __html: htmlData
      }}
      sx={{
        fontFamily: 'primary.normal',
        ...sx,
        '& > mark': {
          bg: 'transparent',
          backgroundImage: `linear-gradient(to right, ${colors.secondary} 0%, ${colors.secondary} 100%)`,
          backgroundSize: 'auto 45%',
          backgroundRepeat: 'repeat-x',
          backgroundPositionY: 'bottom',
          fontFamily: 'primary.italic',
          padding: '0 0.4rem',
          ...underlineSx
        }
      }}
      {...props}
    />
  );
};
