import React from 'react';

// External Components
import { Paragraph, Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';

export const TextBlockWrapper = ({
  data,
  className,
  sx,
  textSx,
  lightText
}) => {
  React.useEffect(() => {
    if (!className) return;

    let elements = gsap.utils.toArray(`.${className} p`);

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.${className}`,
        start: 'top 80%'
      }
    });

    tl.from(elements, {
      y: 50,
      autoAlpha: 0,
      stagger: 0.2,
      duration: 0.8,
      ease: 'power1.out'
    });

    return () => {
      tl.pause(0).kill(true);
      tl.scrollTrigger?.kill(true);
    };
  }, []);

  return (
    // Markup
    <Box
      className={`text-block-wrapper ${className}`}
      sx={{
        display: 'grid',
        gridTemplateColumns:
          data.length === 1
            ? ['1fr', '1fr', '1fr', '3fr 1fr', '2fr 1fr', '2fr 1fr']
            : ['1fr', '1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr'],
        ...sx
      }}>
      {data.map((block) => (
        <Paragraph
          key={block.text}
          sx={{
            color: lightText ? 'background' : 'text',
            fontFamily: 'primary.normal',
            fontFeatureSettings: "'pnum' on, 'onum' on",
            fontSize: [
              '1.6rem',
              '1.7rem',
              '2rem',
              '1.5rem',
              '1.5rem',
              '1.6rem'
            ],
            lineHeight: 1.5,
            ...textSx
          }}>
          {block.text}
        </Paragraph>
      ))}
    </Box>
  );
};
