import React from 'react';

// External Components
import {
  Overlay,
  Box,
  GridWrapper,
  FlexWrapper,
  Link
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Vector, GridSpacer, UnderlineForNavLink } from 'components';

// Self Components
import { NavigationBar, LangSwitchLink } from '../components';

// Assets
import modulOne from 'assets/svg/stackedModule/module-02.svg';
import modulTwo from 'assets/svg/stackedModule/module-03.svg';
import bigNoise from 'assets/png/noise/big-noise-min.png';

// Animations
import {
  slideBackground,
  staggerElements,
  linksReveal,
  elementReveal,
  revealModule
} from './animations';

// helper
import { usePathMatch } from '../helper';

export const NavigationOverlay = ({
  handleClose,
  pageLinks,
  contactLinks,
  language
}) => {
  return (
    <Overlay
      handleClose={handleClose}
      shouldCloseOnBackdropClick
      shouldCloseOnEscapePress
      containerSx={{
        overflow: 'hidden'
      }}>
      <Content
        handleClose={handleClose}
        pageLinks={pageLinks}
        contactLinks={contactLinks}
        language={language}
      />
    </Overlay>
  );
};

const Content = ({ handleClose, pageLinks, contactLinks, language }) => {
  return (
    <Box
      // Animation values
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{ height: '100%', overflowY: 'auto' }}>
      <NavigationBar handleMenuButtonClick={handleClose} isOnOverlay />
      <OverlayBody
        pageLinks={pageLinks}
        contactLinks={contactLinks}
        language={language}
        handleClose={handleClose}
      />
      <Background />
    </Box>
  );
};

const OverlayBody = ({ pageLinks, contactLinks, language, handleClose }) => {
  return (
    <GridWrapper sx={{ position: 'relative', zIndex: 1 }}>
      <LinksWrapper pageLinks={pageLinks} handleClose={handleClose} />
      <LanguageSwitch data={language} handleClose={handleClose} />
      <Contacts contactLinks={contactLinks} />
      <Decoration />
      <BottomSpacer />
    </GridWrapper>
  );
};

const LinksWrapper = ({ pageLinks, handleClose }) => {
  return (
    <FlexWrapper
      // Animation value
      variants={staggerElements}
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '3 / span 20'],
        flexDirection: ['column', 'column', 'column', 'row'],
        flexWrap: [null, null, null, 'wrap'],
        alignItems: 'center',
        justifyContent: 'center',
        // gap: ['4rem', '4.8rem', '5.6rem', '4rem', null, null] //? GAP in flex is not working in safari 14.1
        //? simulate the flex-gap issue in safari 14.1
        //? adjusted to fix the gap issue in safari 14.1
        //? source: https://nosleepjavascript.com/flex-gap-in-unsupported-browsers/
        //? we have to still using flex because in mobile landscape that design is only can be achieved by using flex
        mt: ['11.1rem', '17.6rem', '21.6rem', '10.1rem', null, null],
        mb: [null, null, null, '-2rem', null, null],
        mx: [null, null, null, '-2rem', null, null]
      }}>
      {pageLinks.map((link, index) => (
        <LinkItem key={index} data={link} handleClose={handleClose} />
      ))}
    </FlexWrapper>
  );
};

const LinkItem = ({ data: { text, to }, handleClose }) => {
  const { isPathMatch, pathname } = usePathMatch(to);

  // set the path ref
  const pathRef = React.useRef(null);

  // This Effect make the overlay closed only when the page is succesfully changed
  React.useEffect(() => {
    // set the initial path
    if (!pathRef.current) {
      pathRef.current = pathname;
    }
    // change path if current path is not same with path provided by roter
    if (pathRef.current !== pathname) {
      pathRef.current = pathname;
      handleClose();
    }
  }, [pathname]);
  return (
    // Extra box for animation purposes
    <Box
      // Animation value
      variants={linksReveal}>
      <NavigationLink
        to={to}
        sx={{
          fontFamily: isPathMatch ? 'primary.italic' : 'primary.normal',
          lineHeight: 1,
          fontSize: ['2.6rem', '2.8rem', '3.4rem', '2.2rem', null, null],
          textTransform: 'uppercase',
          '& > a': {
            position: 'relative'
          },

          //? Flex gap issue fix in safari 14.1
          //? source: https://nosleepjavascript.com/flex-gap-in-unsupported-browsers/
          mt: ['4rem', '4.8rem', '5.6rem', '2rem', null, null],
          mb: [null, null, null, '2rem', null, null],
          mx: [null, null, null, '2rem', null, null]
        }}>
        <Box
          as="span"
          sx={{
            position: 'relative',
            zIndex: 1
          }}>
          {text}
        </Box>
        <UnderlineForNavLink isPathMatch={isPathMatch} />
      </NavigationLink>
    </Box>
  );
};

const LanguageSwitch = ({ data: { german, english }, handleClose }) => (
  <FlexWrapper
    className="navigation__language-switch"
    // Animation values
    custom={0.6}
    variants={elementReveal}
    sx={{
      alignSelf: 'center',
      gridColumn: ['1/13', '1/13', '1/25', '18/span 3', null, null],
      justifySelf: 'center',
      display: ['flex', 'flex', 'flex', 'none'],
      mt: ['11.8rem', '14.8rem', '22.7rem', 0]
    }}>
    <OverlayLangSwitch language={german} handleClose={handleClose} />
    <OverlayLangSwitch language={english} handleClose={handleClose} />
  </FlexWrapper>
);

const OverlayLangSwitch = ({ language, handleClose }) => {
  return (
    <LangSwitchLink
      onClick={handleClose}
      language={language.toLowerCase()}
      text={language}
      color="text"
      circleColor="secondary"
      sx={{
        fontSIze: ['1.8rem', '2.2rem', '2.4rem', '1.4rem']
      }}
      circleSx={{
        width: ['5.1rem', '5.5rem', '5.6rem', '4.1rem']
      }}
    />
  );
};

const Contacts = ({ contactLinks }) => {
  return (
    <FlexWrapper
      // Animation values
      custom={0.8}
      variants={elementReveal}
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25'],
        flexDirection: 'column',
        alignItems: 'center',
        mt: ['6.1rem', '6.9rem', '6.7rem', '4.8rem'],
        gap: ['0.8rem', '0.8rem', '0.8rem', '0.5rem']
      }}>
      {contactLinks.map((link, index) => (
        <ContactLinkItem key={index} data={link} />
      ))}
    </FlexWrapper>
  );
};

const ContactLinkItem = ({ data: { link_text, link_to } }) => {
  return (
    <Link
      href={link_to}
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.5,
        fontSize: ['1.7rem', '2rem', '2.2rem', '1.4rem']
      }}>
      {link_text}
    </Link>
  );
};

const Decoration = () => {
  return (
    <>
      <Vector
        // Animation value
        variants={revealModule}
        src={modulOne}
        alt=""
        sx={{
          position: 'absolute',
          gridColumn: ['1/13', '1/13', '1/25', '1/25'],
          width: ['4.3rem', '5.9rem', '6.5rem', '4.3rem'],
          top: ['11.8rem', '16.8rem', '15.8rem', '16.9rem'],
          right: ['-2rem', 0, '3rem', 0]
        }}
      />
      <Vector
        // Animation value
        variants={revealModule}
        src={modulTwo}
        alt=""
        sx={{
          position: 'absolute',
          gridColumn: ['1/13', '1/13', '1/25', '1/25'],
          width: ['11.6rem', '15.6rem', '19.8rem', '11.6rem'],
          top: ['48.5rem', '58rem', '68.1rem', '24.1rem'],
          left: ['-4rem', '-3.3rem', 0, '-0.6rem']
        }}
      />
    </>
  );
};

const Background = () => (
  <Box
    variants={slideBackground}
    sx={{
      backgroundImage: `url(${bigNoise})`,
      backgroundRepeat: 'repeat',
      bg: 'background',
      height: '100%',
      overflowY: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    }}
  />
);

export const BottomSpacer = () => (
  <GridSpacer height={['4.5rem', '9rem', '6.2rem', '2.5rem']} />
);
