import React from 'react';

// External components
import {
  Box,
  Paragraph,
  Heading,
  Link,
  fullWidthMinusMargins,
  FlexWrapper,
  Section
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  UnderlinedHeading,
  HyperLink,
  MdlLogo,
  Vector,
  GridSpacer,
  HorizontalLine,
  Awards,
  NavigationLinkItemWithIndicator
} from 'components';

// Data
import { useFooterData } from './useFooterData';
import { useNavigationData } from 'sections/Navigation/useNavigationData';

// Cms data
import { useKisBusinessContactData } from 'graphqlHooks';

// Assets
import underline from 'assets/svg/underlines/underline_footer.svg';
import modules from 'assets/svg/footer-module-optimized.svg';

export const Footer = () => {
  const { cta, company, sitemap, socials, contact, legal, awards, logos } =
    useFooterData();
  const { pageLinks } = useNavigationData();
  const { contact_links, social_links } = useKisBusinessContactData();

  return (
    // Markup
    <ContentWrapper>
      <CtaHeadline data={cta.headline} />
      <CtaLink data={cta.hyperlink} />
      <Company {...company} />
      <DividerLine position={0} />
      <Spacer position={0} />
      <Sitemap data={sitemap} links={pageLinks} />
      <Socials data={socials} links={social_links} />
      <Contact data={contact} links={contact_links} />
      <Illustration />
      <DividerLine position={1} />
      <Spacer position={1} />
      <CompanyAwards awards={awards} logos={logos} />
      <LegalAndLogo {...legal} logos={logos} />
      <DividerLine position={2} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section
    as="footer"
    sx={{
      overflow: 'hidden',
      pt: ['4.2rem', '7.4rem', '10.5rem', '4rem', '5.5rem', '11rem'],
      pb: ['5.5rem', '5rem', '4.4rem', '4.8rem', '8.4rem', '5rem']
    }}>
    {children}
  </Section>
);

const CtaHeadline = ({ data }) => (
  <UnderlinedHeading
    as="h2"
    data={data}
    sx={{
      fontSize: ['3rem', '4.4rem', '4.8rem', '3.2rem', '3.8rem', '4rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '1/span 18',
        '1/span 12',
        '1/span 10',
        '2/span 8'
      ],
      gridRow: 1
    }}
  />
);

const CtaLink = ({ data }) => (
  <HyperLink
    {...data}
    sx={{
      gridColumn: [
        '5/span 7',
        '1/span 5',
        '1/span 8',
        '1/span 6',
        '1/span 6',
        '2/span 5'
      ],
      gridRow: 2,
      justifySelf: ['center', 'start', 'start', 'start', 'start', 'start'],
      mt: ['5.2rem', '4.4rem', '5.7rem', '5.1rem', '5.8rem', '8.7rem']
    }}
  />
);

const Company = ({ description }) => (
  <Box
    className="footer__company"
    sx={{
      gridColumn: ['1/13', '5/13', '12/25', '14/25', '15/25', '16/span 8'],
      gridRow: [3, 3, 3, '1/span 3', '1/span 2', '1/span 2'],
      mt: ['9.9rem', '10.5rem', 0, 0, 0, 0]
    }}>
    <MdlLogo
      sx={{
        maxWidth: [
          '18.4rem',
          '21rem',
          '25.4rem',
          '18.4rem',
          '25.4rem',
          '25.4rem'
        ]
      }}
    />
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: description
      }}
      sx={{
        fontFamily: 'primary.normal',
        fontSize: ['1.5rem', '1.7rem', '1.5rem', '1.4rem', '1.5rem', '1.5rem'],
        lineHeight: 1.5,
        mt: ['1.4rem', '1.6rem', '1.8rem', '1.2rem', '1.5rem', '2.3rem'],
        '& > span': {
          fontFamily: 'primary.bold'
        }
      }}
    />
    <Vector
      src={underline}
      alt=""
      sx={{
        mt: '0.8rem',
        width: [
          '18.4rem',
          '18.4rem',
          '20.2rem',
          '18.4rem',
          '20.2rem',
          '20.2rem'
        ]
      }}
    />
  </Box>
);

const Sitemap = ({ data: { headline }, links }) => (
  <Box
    className="footer__sitemap-link-list"
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '1/13',
        '1/span 4',
        '12/span 11',
        '1/span 15',
        '15/span 9',
        '16/span 7'
      ],
      gridRow: [5, '5/span 2', 6, 7, 5, 5],
      mt: [0, 0, 0, '7.2rem', 0, 0]
    }}>
    <ListHeadline data={headline} />
    <LinkListWrapper
      sx={{
        gridTemplateColumns: [
          '49% 49%',
          '1fr',
          '43% 43%',
          '25% 32% 25%',
          '43% 43%',
          '40% 40%'
        ],
        justifyContent: 'space-between'
      }}>
      {links.map((link, index) => (
        <NavigationLinkItemWithIndicator
          key={index}
          link={link}
          variant="footer"
        />
      ))}
    </LinkListWrapper>
  </Box>
);

const Socials = ({ data: { headline }, links }) => (
  <RegularLinkList
    headline={headline}
    links={links}
    sx={{
      gridColumn: [
        '1/span 6',
        '7/span 4',
        '1/span 6',
        '14/span 6',
        '9/span 4',
        '9/span 4'
      ],
      gridRow: [6, 5, 6, 6, 5, 5],
      mt: ['5.4rem', 0, 0, 0, 0, 0]
    }}
  />
);

const Contact = ({ data: { headline }, links }) => (
  <RegularLinkList
    headline={headline}
    links={links}
    sx={{
      gridColumn: [
        '1/span 9',
        '7/13',
        '1/span 8',
        '1/span 8',
        '1/span 5',
        '2/span 5'
      ],
      gridRow: [7, 6, 7, 6, 5, 5],
      mt: ['5.4rem', '7.8rem', '1.8rem', 0, 0, 0]
    }}
  />
);

const Illustration = () => (
  <Vector
    src={modules}
    alt=""
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '1/span 22',
        '1/span 19',
        '1/span 15',
        '1/span 14'
      ],
      gridRow: [8, 7, 8, 8, 6, 6],
      mt: ['6.5rem', '8.8rem', '8rem', '6.1rem', '12.2rem', '4.2rem'],
      ml: ['-72%', '-31%', 0, 0, 0, 0],
      width: ['71.1rem', '80.8rem', '100%', '100%', '100%', '100%']
    }}
  />
);

const CompanyAwards = ({ awards, logos: { kfh } }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/span 11',
          '1/span 8',
          '17/25',
          '17/25',
          '18/25',
          '18/ span 6'
        ],
        gridRow: [null, null, 11, 11, 9, 9],
        display: 'flex',
        flexDirection: 'column',
        alignItems: [
          'flex-start',
          'flex-start',
          'flex-end',
          'flex-end',
          'flex-end'
        ]
      }}>
      <Awards
        data={awards}
        sx={{
          width: '100%',
          mt: 0
        }}
        imageSx={{
          height: ['8rem', '10rem', '7.9rem', '7.3rem', '8rem', '7.9rem']
        }}
      />
      <Vector
        src={kfh.src.publicURL}
        alt={kfh.alt}
        sx={{
          mt: ['1.5rem', '2rem', '1.5rem', '1.8rem', '2.4rem', '2rem'],
          width: [
            '21rem',
            '25.3rem',
            '16.8rem',
            '16.6rem',
            '20.2rem',
            '22.3rem'
          ]
        }}
      />
    </Box>
  );
};

const LegalAndLogo = ({
  links,
  copyright
  // logos: { thePuzzlers }
}) => (
  <Box
    className="footer__legal"
    sx={{
      alignSelf: ['start', 'start', 'center', 'center', 'center', 'center'],
      gridColumn: [
        '1/13',
        '1/span 8',
        '1/span 10',
        '1/span 10',
        '1/span 8',
        '2/span 6'
      ],
      gridRow: [null, null, 11, 11, 9, 9],
      mt: ['8.2rem', '7.5rem', 0, 0, 0, 0]
    }}>
    <FlexWrapper
      sx={{ gap: ['3.4rem', '3.9rem', '3.3rem', '2.9rem', '3.9rem', '4rem'] }}>
      {links.map((link) => (
        <NavigationLink key={link.to} to={link.to} variant="legal">
          {link.text}
        </NavigationLink>
      ))}
    </FlexWrapper>
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: copyright
      }}
      sx={{
        fontSize: ['1.5rem', '1.5rem', '1.4rem', '1.3rem', '1.4rem', '1.4rem'],
        lineHeight: 1,
        mt: ['1.6rem', '2.4rem', '1.8rem', '1.3rem', '1.5rem', '1.3rem'],
        '& > span': { fontFamily: 'body.bold' }
      }}
    />
    {/* <Link
      href={thePuzzlers.web}
      variant="footer"
      target="_blank"
      rel="noreferrer noopener"
      sx={{
        mt: ['2rem', '3rem', '2rem', '2rem', '3rem', '4rem'],
        display: 'block'
      }}>
      <Vector
        src={thePuzzlers.src.publicURL}
        alt={thePuzzlers.alt}
        sx={{
          width: ['15rem', '15rem', '15rem', '13rem', '15rem', '15rem']
        }}
      />
    </Link> */}
  </Box>
);

// Reusable Components

const RegularLinkList = ({ headline, links, sx }) => (
  <Box className="footer__regular-link-list" sx={sx}>
    <ListHeadline data={headline} />
    <LinkListWrapper>
      {links.map((link) => (
        <Link
          key={link.to ? link.to : link.link_to}
          href={link.to ? link.to : link.link_to}
          variant="footer"
          target="_blank"
          rel="noreferrer noopener">
          {link.text ? link.text : link.link_text}
        </Link>
      ))}
    </LinkListWrapper>
  </Box>
);

const LinkListWrapper = ({ children, sx }) => (
  <Box
    className="link-list-wrapper"
    sx={{
      display: 'grid',
      gap: [
        '2.4rem 0',
        '3.2rem 0',
        '3.2rem 0',
        '3.2rem 0',
        '3.2rem 0',
        '3.2rem 0'
      ],
      mt: ['2.8rem', '2.3rem', '2.1rem', '2.5rem', '3rem', '2.8rem'],
      ...sx
    }}>
    {children}
  </Box>
);

const ListHeadline = ({ data }) => (
  <Heading
    as="h4"
    sx={{
      fontFamily: 'body.normal',
      fontSize: ['1.4rem', '1.5rem', '1.4rem', '1.3rem', '1.4rem', '1.4rem'],
      textTransform: 'uppercase',
      lineHeight: 1
    }}>
    {data}
  </Heading>
);

const DividerLine = ({ position }) => {
  const styles = [
    {
      gridRow: [null, null, 4, 4, 3, 3],
      display: ['none', 'none', 'block', 'block', 'block', 'block'],
      mt: [null, null, '7.4rem', '7.6rem', '10.8rem', '13rem']
    },
    {
      gridRow: [9, 8, 9, 9, 7, 7],
      mt: ['2.3rem', '3.2rem', '4rem', '4.3rem', '5.4rem', '6.9rem']
    },
    {
      gridRow: [null, null, 12, 12, 10, 10],
      mt: ['3.2rem', '3.2rem', '4.7rem', '3.2rem', '5rem', '4rem']
    }
  ];

  return (
    <HorizontalLine
      isThicker={false}
      sx={{
        ...styles[position],
        bg: 'text',
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
        mx: [fullWidthMinusMargins[0], fullWidthMinusMargins[1], 0, 0, 0, 0],
        width: 'auto'
      }}
    />
  );
};

// Spacer

const Spacer = ({ position }) => {
  const styles = [
    {
      gridRow: [4, 4, 5, 5, 4, 4],
      height: ['11.2rem', '10.2rem', '8rem', '6.4rem', '6.4rem', '5.5rem']
    },
    {
      gridRow: [10, 9, 10, 10, 8, 8],
      height: ['3.2rem', '3.2rem', '3.2rem', '3.2rem', '4rem', '4rem']
    }
  ];

  return <GridSpacer sx={{ ...styles[position] }} />;
};
