import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { CoreLayout } from 'gatsby-theme-thepuzzlers-core';
import { Box } from '@thepuzzlers/pieces';

// GSAP
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Sections
import { Footer, Navigation } from '../sections';

// Fonts
import 'gatsby-plugin-theme-ui/fonts/fontImports';

// CSS Animations
import 'animations/keyframes.css';

// Assets
import bigNoise from 'assets/png/noise/big-noise-min.png';

import { useScrollTriggerRefresh } from 'hooks/useScrollTriggerRefresh';

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children, pageContext }) => {
  useScrollTriggerRefresh();
  let fontSizeFactor = 1;

  let lastHeight = 0;
  let lastWidth = 0;

  const setDocHeight = () => {
    if (
      Math.abs(lastWidth - window.visualViewport.width) > 100 ||
      Math.abs(lastHeight - window.visualViewport.height) > 100
    ) {
      lastHeight = window.visualViewport.height;
      lastWidth = window.visualViewport.width;

      document.documentElement.style.setProperty(
        '--vh',
        `${window.visualViewport.height / 100}px`
      );
    }
  };

  React.useEffect(() => {
    setDocHeight();
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);
  }, []);

  const mediaQueries = `
  html {
    font-size: calc(${9.365 * fontSizeFactor}px + ((100vw - 320px) * ${
    9.365 * fontSizeFactor
  } / 2.5 / 320)) !important;
}

  @media screen and (min-width: 500px) {
      html {
        font-size: calc(${8.99 * fontSizeFactor}px + ((100vw - 500px) * ${
    8.99 * fontSizeFactor
  } / 2.5 / 500)) !important;
      }
    }
    
    @media screen and (min-width: 750px) and (orientation: portrait) {
      html {
      font-size: calc(${9.57 * fontSizeFactor}px + ((100vw - 750px) * ${
    9.57 * fontSizeFactor
  } / 2.5 / 750)) !important;
      }
    }        
    
    @media screen and (min-width: 700px) and (orientation: landscape) {
      html {
      font-size: calc(${9.4 * fontSizeFactor}px + ((100vw - 700px) * ${
    9.4 * fontSizeFactor
  } / 2.5 / 700)) !important;
      }
    }
     
    @media screen and (min-width: 1000px) {
      html {
      font-size: calc(${9.28 * fontSizeFactor}px + ((100vw - 1000px) * ${
    9.28 * fontSizeFactor
  } / 2.5 / 1000)) !important;
      }
    }
    
    @media screen and (min-width: 1400px) {
      html {
        font-size: calc(${9.89 * fontSizeFactor}px + ((100vw - 1400px) * ${
    9.89 * fontSizeFactor
  } / 2.5 / 1400)) !important;
}
  }
  `;

  return (
    // CoreLayout includes PageContextProvider & Global component
    <CoreLayout
      pageContext={pageContext}
      styles={{
        bg: 'background',
        backgroundImage: `url(${bigNoise})`,
        backgroundRepeat: 'repeat',
        objectFit: 'none'
      }}>
      <Navigation />
      <Box
        as="main"
        sx={{
          position: 'relative',
          overflow: 'hidden'
        }}>
        {children}
      </Box>
      <Footer />
      {/* this needed to handle fontsize load at first page load */}
      <Helmet onChangeClientState={() => ScrollTrigger.refresh()}>
        <style type="text/css">{mediaQueries}</style>
      </Helmet>
    </CoreLayout>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Layout;
