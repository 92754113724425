import React from 'react';

import { Heading } from '@thepuzzlers/pieces';

export const HighlightedHeading = ({ data, sx, ...props }) => {
  return (
    <Heading
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        color: 'currentColor',
        fontFamily: 'primary.normal',
        '& > span': {
          fontFamily: 'primary.italic',
          color: 'secondary'
        },
        ...sx
      }}
      {...props}
    />
  );
};
