import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from './ImageContainer';

// Assets
import smallNoise from 'assets/png/noise/small-noise-min.png';
import normalNoise from 'assets/png/noise/normal-noise-min.png';

export const IllustrationBlockWithNoise = ({
  className,
  bg = 'primary',
  data,
  sx,
  imgSx,
  ...props
}) => {
  const noise = bg === 'primary' ? smallNoise : normalNoise;
  return (
    <Box
      className={`illustration-block-with-noise ${className}`}
      sx={{
        bg,
        backgroundImage: `url(${noise})`,
        backgroundRepeat: 'repeat',
        p: ['1.9rem', '2.8rem', '2.5rem', '2rem', '4.1rem', '4.1rem'],
        ...sx
      }}
      {...props}>
      <ImageContainer
        className="illustration-block-with-noise__image"
        {...data}
        sx={{
          aspectRatio: '1/1',
          borderRadius: 'unset',
          zIndex: 2,
          ...imgSx
        }}
      />
    </Box>
  );
};

export const IllustrationBlock = ({ data, bg, sx, ...props }) => (
  <ImageContainer
    className="illustration-block"
    {...data}
    sx={{
      aspectRatio: '1/1',
      bg,
      boxShadow: 700,
      p: ['1.2rem', '1.9rem', '2rem', '1.2rem', '1.8rem', '2rem'],
      ...sx
    }}
    {...props}
  />
);
