const shadows = {
  300: '0px 12px 18px rgba(0, 0, 0, 0.08)',
  500: '0px 18px 24px rgba(0, 0, 0, 0.2)',
  700: '0px 32px 48px rgba(0, 0, 0, 0.25)'
};

const radii = {
  card: '0.4rem'
};

export { shadows, radii };
