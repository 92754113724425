import React from 'react';

// External Components
import {
  Box,
  Paragraph,
  fullWidthMinusMargins,
  Button
} from '@thepuzzlers/pieces';
import { AnimatePresence } from 'framer-motion';

// Local Components
import {
  AspectRatioImageContainer,
  ImageContainer,
  Vector,
  VideoOverlay
} from 'components';

// Assets
import play from 'assets/svg/play.svg';
import underline from 'assets/svg/underlines/underline_video.svg';

export const VideoPlayer = ({
  videoCode,
  data: { text, image, noise },
  className,
  sx,
  ...props
}) => {
  const [openOverlay, setOpenOverlay] = React.useState(false);
  const handleClick = () => setOpenOverlay(!openOverlay);

  return (
    <>
      <Button
        className={`play-video-block ${className}`}
        onClick={handleClick}
        variant="clear"
        sx={{
          cursor: 'pointer',
          gridColumn: [
            '1/13',
            '1/13',
            '1/25',
            '1/25',
            '3/span 20',
            '4/span 18'
          ],
          mx: [
            fullWidthMinusMargins[0],
            fullWidthMinusMargins[1],
            fullWidthMinusMargins[2],
            0,
            0,
            0
          ],
          mt: ['12.7rem', '10.9rem', '10.7rem', '3.8rem', '5rem', '5.8rem'],
          position: 'relative',
          zIndex: 1,

          ...sx
        }}
        {...props}>
        <AspectRatioImageContainer
          aspectRatios={[
            1 / 0.85,
            1 / 0.85,
            1 / 0.64,
            1 / 0.5,
            1 / 0.5,
            1 / 0.5
          ]}
          className="play-video-block__image"
          {...image}
          sx={{
            borderRadius: ['unset', 'unset', 'unset', 'card', 'card', 'card']
          }}
        />
        <ImageContainer
          className="play-video-block__noise"
          {...noise}
          sx={{
            borderRadius: 'unset',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1
          }}
        />
        <PlayVideoText data={text} />
      </Button>
      <AnimatePresence>
        {openOverlay && (
          <VideoOverlay handleClose={handleClick} videoCode={videoCode} />
        )}
      </AnimatePresence>
    </>
  );
};

const PlayVideoText = ({ data }) => (
  <Box
    className="play-video-block__text"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2
    }}>
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: data
      }}
      sx={{
        color: 'background',
        fontSize: ['8rem', '12rem', '13rem', '8rem', '10rem', '12rem'],
        fontFamily: 'secondary.normal',
        lineHeight: 1,
        position: 'relative',
        whiteSpace: 'nowrap',
        zIndex: 1,
        '& > .video__play-spacer': {
          display: 'inline-block'
        }
      }}
    />
    <Vector
      src={play}
      alt="Play Icon"
      sx={{
        position: 'absolute',
        top: '40%',
        left: '45%',
        width: ['2.6rem', '4.7rem', '5.1rem', '2.6rem', '3.2rem', '3.6rem']
      }}
      // Animation values
      animate={{ x: [-5, 5] }}
      transition={{
        type: 'spring',
        bounce: 0.5,
        duration: 0.8,
        repeat: Infinity,
        repeatType: 'reverse'
      }}
    />
    <Vector
      src={underline}
      alt=""
      sx={{
        position: 'absolute',
        left: '50%',
        bottom: [
          '-0.9rem',
          '-1.7rem',
          '-1.8rem',
          '-0.9rem',
          '-1.1rem',
          '-1rem'
        ],
        transform: 'translateX(-50%)',
        width: [
          '21.6rem',
          '34.8rem',
          '37.5rem',
          '21.6rem',
          '29.2rem',
          '32.7rem'
        ]
      }}
    />
  </Box>
);

const playIconVariant = {
  animate: {
    x: [-5, 5],
    transition: {
      default: {
        type: 'spring',
        bounce: 0.5,
        duration: 0.8,
        repeat: Infinity,
        repeatType: 'reverse'
      }
    }
  }
};
