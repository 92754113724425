const linkDefault = {
  cursor: 'pointer',
  transition: '0.2s ease-out',
  ':hover': {
    transition: '0.2s ease-out'
  }
};

const buttonDefault = {
  cursor: 'pointer',
  transition: '0.2s ease-out'
};

const hyperlinkDefault = {
  ...linkDefault,
  borderRadius: '0.4rem',
  display: 'block',
  fontFamily: 'secondary.normal',
  lineHeight: 1,
  position: 'relative',
  whiteSpace: 'nowrap',
  width: 'max-content',
  zIndex: 1,
  trasnsition: 'tranform 1s ease',
  ':hover': {
    transform: 'translateY(-10%)'
  }
};

const links = {
  navigation: {
    cursor: 'pointer',
    display: 'block',
    fontFamily: 'primary.bold',
    fontSize: [null, null, null, null, '1.5rem', '1.5rem'], // to do: fill null when we have nav overlay design
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  hyperlink: {
    // small
    primary: {
      ...hyperlinkDefault,
      fontSize: ['2.6rem', '2.6rem', '2.8rem', '2.2rem', '2.8rem', '3.2rem'],
      p: [
        '0.8rem 2.4rem',
        '0.8rem 2.4rem',
        '0.8rem 2.4rem',
        '0.8rem 2.4rem',
        '0.8rem 2.8rem',
        '0.8rem 2.8rem'
      ]
    },
    // big
    secondary: {
      ...hyperlinkDefault,
      fontSize: ['2.4rem', '2.6rem', '2.8rem', '2.4rem', '2.8rem', '3.2rem'],
      p: [
        '1rem 2rem',
        '1rem 2.4rem',
        '1rem 2.4rem',
        '1rem 2rem',
        '1.2rem 2.8rem',
        '1.4rem 2.8rem'
      ]
    }
  },
  footer: {
    ...linkDefault,
    display: 'block',
    fontFamily: 'primary.bold',
    fontSize: ['1.7rem', '1.9rem', '1.7rem', '1.7rem', '1.7rem', '1.8rem'],
    lineHeight: 1,
    whiteSpace: 'nowrap',
    width: 'max-content'
  },
  legal: {
    ...linkDefault,
    display: 'block',
    fontFamily: 'body.normal',
    fontSize: ['1.5rem', '1.5rem', '1.4rem', '1.3rem', '1.4rem', '1.4rem'],
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  contact: {
    ...linkDefault,
    color: 'background',
    display: 'block',
    fontFamily: 'primary.bold',
    fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.5rem', '1.8rem', '1.8rem'],
    lineHeight: 1.5
  }
};

const buttons = {
  primary: {
    ...buttonDefault
  },
  menu: {
    bg: 'transparent',
    cursor: 'pointer',
    fontFamily: 'body.bold',
    fontSize: ['1.4rem', '1.6rem', '1.8rem', '1.4rem', null, null], // null because it's not visible in last two breakpoints
    letterSpacing: '0.1rem',
    lineHeight: 1,
    textTransform: 'uppercase',
    p: 0
  },
  langSwitch: {
    fontFamily: 'primary.bold',
    fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.4rem', '1.5rem', '1.5rem'],
    lineHeight: 1,
    textTransform: 'uppercase',
    p: [
      '1.4rem 1rem',
      '1.4rem 1rem',
      '1.5rem 1rem',
      '1.1rem 1rem',
      '1.6rem 1.2rem',
      '1.6rem 1.2rem'
    ],
    position: 'relative',
    zIndex: 1
  },
  clear: {
    ...buttonDefault,
    p: 0,
    bg: 'transparent'
  }
};

const cards = {};

export { links, buttons, cards };
