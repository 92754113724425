import React from 'react';

// External Components
import { fullWidthMinusMargins } from '@thepuzzlers/pieces';

// Local Components
import { HorizontalLine } from './HorizontalLine';

export const DividerLine = ({ sx }) => (
  <HorizontalLine
    isThicker={false}
    sx={{
      bg: 'text',
      mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
      width: 'auto',
      ...sx
    }}
  />
);
