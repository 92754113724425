import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Assets
import bigNoise from 'assets/png/noise/big-noise-min.png';
import smallNoise from 'assets/png/noise/small-noise-min.png';
import mediumNoise from 'assets/png/noise/normal-noise-min.png';

// TODO: Remove unused noise assets

export const NoiseSection = ({ children, id, sx, ...props }) => (
  <Section
    id={id}
    sx={{
      backgroundSize: 'contain',
      backgroundRepeat: 'repeat',
      objectFit: 'none',
      ...sx
    }}
    {...props}>
    {children}
  </Section>
);

export const TealNoiseSection = ({ sx, ...props }) => (
  <NoiseSection
    sx={{
      bg: 'primary',
      backgroundImage: `url(${smallNoise})`,

      ...sx
    }}
    {...props}
  />
);

export const LimeNoiseSection = ({ sx, ...props }) => (
  <NoiseSection
    sx={{
      bg: 'secondary',
      backgroundImage: `url(${mediumNoise})`,
      ...sx
    }}
    {...props}
  />
);
