import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

//  TODO: navigation links for the footer should take from the navigation data
export const useFooterData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: footerDataJson {
        cta {
          headline
          hyperlink {
            text
            to
          }
        }
        company {
          description
        }
        sitemap {
          headline
        }
        socials {
          headline
        }
        contact {
          headline
        }
        legal {
          links {
            text
            to
          }
          copyright
        }
        awards {
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
          alt
        }
        logos {
          thePuzzlers {
            src {
              publicURL
            }
            alt
            web
          }
          kfh {
            src {
              publicURL
            }
            alt
          }
        }
      }
      de: deFooterDataJson {
        cta {
          headline
          hyperlink {
            text
            to
          }
        }
        company {
          description
        }
        sitemap {
          headline
        }
        socials {
          headline
        }
        contact {
          headline
        }
        legal {
          links {
            text
            to
          }
          copyright
        }
        awards {
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
          alt
        }
        logos {
          thePuzzlers {
            src {
              publicURL
            }
            alt
            web
          }
          kfh {
            src {
              publicURL
            }
            alt
          }
        }
      }
    }
  `);

  return data[locale];
};
