import React from 'react';

// External components
import { GridWrapper, Button, Box, FlexWrapper } from '@thepuzzlers/pieces';
import {
  NavigationLink,
  LanguageSwitchLink,
  useRevertPathToDefaultLocale
} from 'gatsby-theme-thepuzzlers-intl';
import { useLocation } from '@gatsbyjs/reach-router';

// Local Components
import {
  MdlLogo,
  SelectedCircle,
  UnderlineForNavLink,
  NavigationLinkItemWithIndicator
} from 'components';

// Data
import { useNavigationData } from '../useNavigationData';

// helper
import { usePathMatch } from '../helper';

// Animation
import {
  topMenuLineAnimation,
  bottomMenuLineAnimation,
  textMenuAnimation,
  onScrollNavigationBarAnimation
} from '../animation';

export const NavigationBar = ({ handleMenuButtonClick, isOnOverlay, sx }) => {
  const { menuBtn, pageLinks, language } = useNavigationData();

  const { pathname } = useLocation();
  const pageName = useRevertPathToDefaultLocale(pathname)
    // Remove all slashes from string
    .replace(/\//g, '');

  const isHeaderYellow = pageName === 'inspiration';
  const areElementsDark =
    pageName !== 'sustainability' &&
    pageName !== 'team' &&
    pageName !== 'contact'
      ? true
      : false;
  const color = areElementsDark || isOnOverlay ? 'text' : 'background';

  React.useEffect(() => {
    !isOnOverlay && onScrollNavigationBarAnimation();
  }, []);

  return (
    // Markup
    <ContentWrapper sx={sx}>
      <Logo color={color} />
      <Navlinks
        data={pageLinks}
        color={color}
        isHeaderYellow={isHeaderYellow}
      />
      <LanguageSwitch
        data={language}
        color={color}
        circleColor={isHeaderYellow ? 'text' : 'secondary'}
        isOnOverlay={isOnOverlay}
      />
      <MenuButton
        data={menuBtn}
        color={color}
        onClick={handleMenuButtonClick}
        isOnOverlay={isOnOverlay}
      />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children, sx }) => (
  <GridWrapper
    as="nav"
    className="navigation-bar-wrapper"
    sx={{
      position: 'fixed',
      py: ['1.2rem', '2.8rem', '3rem', '1.8rem', '3rem', '2.5rem'],
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 10,
      ...sx
    }}>
    {children}
  </GridWrapper>
);

const Logo = ({ color }) => (
  <NavigationLink
    to="/"
    sx={{
      alignSelf: 'center',
      gridColumnStart: 1,
      width: ['18.4rem', '19.7rem', '21.1rem', '18.4rem', '20.2rem', '20.9rem']
    }}>
    <MdlLogo
      className="navigation-bar__mdl-logo"
      sx={{ color, width: '100%' }}
    />
  </NavigationLink>
);

const MenuButton = ({ data, color, onClick, isOnOverlay }) => (
  <Button
    className="navigation__menu-button"
    variant="menu"
    onClick={onClick}
    sx={{
      alignSelf: 'center',
      color,
      display: ['block', 'block', 'block', 'block', 'none', 'none'],
      gridColumnEnd: [13, 13, 25, 25, null, null],
      justifySelf: 'end'
    }}>
    <MenuLine
      color={color}
      // Animation value
      variants={isOnOverlay && topMenuLineAnimation}
    />
    <Box
      as="span"
      sx={{
        display: 'block',
        // need to be same for all breakpoitnts to make animation correct
        p: '0.8rem 0.7rem'
      }}
      // Animation value
      variants={isOnOverlay && textMenuAnimation}>
      {data}
    </Box>
    <MenuLine
      color={color}
      // Animation value
      variants={isOnOverlay && bottomMenuLineAnimation}
    />
  </Button>
);

const LanguageSwitch = ({
  data: { german, english },
  color,
  circleColor,
  isOnOverlay
}) => (
  <FlexWrapper
    className="navigation__language-switch"
    sx={{
      alignSelf: 'center',
      display: isOnOverlay
        ? ['none', 'none', 'none', 'flex', null, null]
        : ['none', 'flex', 'flex', 'flex', 'flex', 'flex'],
      gridColumn: [
        null,
        '8/span 3',
        '17/span 4',
        '17/span 4',
        '22/25',
        '23/25'
      ],
      justifySelf: [null, 'center', 'end', 'end', 'end', 'end']
    }}>
    <LangSwitchLink
      language={german.toLowerCase()}
      text={german}
      color={color}
      circleColor={circleColor}
    />
    <LangSwitchLink
      language={english.toLowerCase()}
      text={english}
      color={color}
      circleColor={circleColor}
    />
  </FlexWrapper>
);

const Navlinks = ({ data, color, isHeaderYellow }) => (
  <FlexWrapper
    className="navigation__nav-links"
    sx={{
      alignSelf: 'center',
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      gap: '3.2rem',
      gridColumn: [null, null, null, null, '9/span 12', '7/span 10'],
      justifySelf: [null, null, null, null, 'end', 'start']
    }}>
    {data.map((link) => (
      <NavigationLinkItemWithIndicator
        key={link.to}
        variant="navigation"
        link={link}
        color={color}
        isHeaderYellow={isHeaderYellow}
      />
    ))}
  </FlexWrapper>
);

// Reusable Components

const MenuLine = ({ color, ...props }) => (
  <Box
    className="navigation__menu-line"
    as="span"
    sx={{
      backgroundColor: color,
      display: 'block',
      height: '0.3rem',
      width: '100%'
    }}
    {...props}
  />
);

export const LangSwitchLink = ({
  text,
  color,
  language,
  circleColor,
  sx,
  circleSx,
  onClick
}) => (
  <Box className="language-switch__link-wrapper" sx={{ position: 'relative' }}>
    <LanguageSwitchLink
      // className="navigation-bar__lang-switch-link"
      sx={{
        '&:hover, &.active ': {
          '& ~ svg': { transform: 'translate(-50%, -50%) scale(1)' }
        },
        color,
        '& a': {
          variant: 'buttons.langSwitch'
        },
        ...sx
      }}
      language={language}
      onClick={onClick}>
      {text}
    </LanguageSwitchLink>
    <SelectedCircle
      sx={{
        color: circleColor,
        position: 'absolute',
        pointerEvents: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.3s ease-out',
        width: ['4.3rem', '4.3rem', '4.7rem', '4.1rem', '4.8rem', '4.8rem'],
        ...circleSx
      }}
    />
  </Box>
);
