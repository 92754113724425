import React from 'react';

// External Components
import { Box, FlexWrapper, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { AspectRatioImageContainer, MarkedHeading } from 'components';

export const LeadPhoto = ({ image, name, position, sx, ...props }) => (
  <FlexWrapper
    sx={{
      alignItems: [
        'baseline',
        'center',
        'center',
        'center',
        'center',
        'center'
      ],
      flexDirection: ['column', 'row', 'row', 'row', 'row', 'row'],
      gridColumn: [
        '1/span 8',
        '1/span 10',
        '2/span 16',
        '1/span 16',
        '1/span 10',
        '1/span 9'
      ],
      gridRow: 1,
      ...sx
    }}
    {...props}>
    <AspectRatioImageContainer
      {...image}
      aspectRatios={1.38 / 1}
      sx={{
        width: ['15.7rem', '16.6rem', '16.6rem', '10.7rem', '11.8rem', '13rem']
      }}
    />
    <NameAndPosition
      name={name}
      position={position}
      sx={{
        mt: ['1.6rem', 0, 0, 0, 0, 0],
        ml: [null, '5.4rem', '4rem', '4.1rem', '5rem', '2.7rem']
      }}
    />
  </FlexWrapper>
);

export const NameAndPosition = ({ name, position, sx }) => (
  <Box
    className="team-member__name-and-position"
    sx={{
      mt: ['2.4rem', '3rem', '3.8rem', '2.4rem', '1.2rem', '1.2rem'],
      ...sx
    }}>
    <MarkedHeading
      as="h3"
      data={name}
      sx={{
        fontSize: ['1.8rem', '1.8rem', '2.2rem', '1.6rem', '1.8rem', '2rem'],
        lineHeight: 1.25
      }}
    />
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        fontSize: ['1.4rem', '1.4rem', '1.6rem', '1.4rem', '1.4rem', '1.4rem'],
        lineHeight: 1.15,
        mt: '0.6rem'
      }}>
      {position}
    </Paragraph>
  </Box>
);
