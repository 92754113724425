import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const HorizontalLine = ({ bg = 'background', isThicker = true, sx }) => {
  return (
    <Box
      className="horizontal-line"
      sx={{ bg, height: isThicker ? '0.2rem' : '0.1rem', width: '100%', ...sx }}
    />
  );
};
