import React from 'react';

// Local Components
import { Scribble } from '../svgs';

export const LinkScribble = ({ color, primary }) => (
  <Scribble
    sx={{
      color,
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -40%)',
      maxWidth: primary
        ? ['9.4rem', '10.7rem', '10.7rem', '8.9rem', '11.5rem', '11.3rem']
        : ['11.5rem', '11.6rem', '14.9rem', '12.9rem', '15.3rem', '18.2rem'],
      width: '80%'
    }}
  />
);
