import React from 'react';

// External Components
import Vimeo from '@u-wave/react-vimeo';

import { useIntl } from 'gatsby-theme-thepuzzlers-intl';
import {
  Overlay,
  GridWrapper,
  Box,
  Button,
  Image,
  Paragraph,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';

// Assets
import close from 'assets/svg/close.svg';

// Animations
import { slideInAnimation } from './animations';

const LOADING_MESSAGE = {
  de: 'Das Video wird geladen...',
  en: 'Loading the video...'
};

export const VideoOverlay = ({ handleClose, videoCode }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const handleLoad = () => setIsLoading(false);

  return (
    // Markup
    <ContentWrapper handleClose={handleClose}>
      <CloseBtn handleClose={handleClose} />
      <Video
        videoCode={videoCode}
        isLoading={isLoading}
        handleLoad={handleLoad}
      />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children, handleClose }) => (
  <Overlay
    handleClose={handleClose}
    shouldCloseOnBackdropClick
    shouldCloseOnEscapePress
    containerSx={{
      overflow: 'hidden'
    }}>
    <GridWrapper
      // Animation values
      variants={slideInAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        bg: 'rgba(0,0,0, 0.5)',
        height: '100vh',
        py: ['2rem', '2.4rem', '4rem', '2rem', '4rem', '4rem']
      }}>
      {children}
    </GridWrapper>
  </Overlay>
);

const CloseBtn = ({ handleClose }) => (
  <Button
    onClick={handleClose}
    variant="clear"
    sx={{
      alignSelf: 'start',
      gridColumnEnd: [13, 13, 25, 25, 25, 25],
      gridRow: 1,
      justifySelf: 'end',
      position: 'relative',
      width: ['2rem', '2.2rem', '2.8rem', '2rem', '2.4rem', '2.4rem'],
      zIndex: 10
    }}>
    <Image src={close} alt="Close icon" />
  </Button>
);

const Video = ({ videoCode, isLoading, handleLoad }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '2/11',
          '1/25',
          '3/span 20',
          '3/span 20',
          '3/span 20'
        ],
        gridRow: 1,
        display: 'grid',
        mx: [fullWidthMinusMargins[0], fullWidthMinusMargins[1], 0, 0, 0, 0]
      }}>
      {isLoading && <Loading />}
      <Vimeo
        video={videoCode}
        style={{ marginTop: 'auto', marginBottom: 'auto' }}
        responsive
        autoplay={false}
        dnt
        onLoaded={handleLoad}
      />
    </Box>
  );
};

const Loading = () => {
  const { locale } = useIntl();

  return (
    <Paragraph
      sx={{
        color: 'background',
        fontFamily: 'body.normal',
        fontSize: ['1.8rem', '2rem', '2.2rem', '1.8rem', '2.2rem', '2.2rem'],
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        width: '100%'
      }}>
      {locale === 'de' ? LOADING_MESSAGE.de : LOADING_MESSAGE.en}
    </Paragraph>
  );
};
