import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { RegularLink } from './RegularLink';
import { LinkScribble } from './LinkScribble';

export const HyperLink = ({
  to,
  text,
  isDark,
  primary = false,
  scribbleColor = 'text',
  sx
}) => (
  <Box
    className="hyperlink"
    sx={{
      position: 'relative',
      width: 'max-content',
      ...sx
    }}>
    <RegularLink to={to} text={text} primary={primary} isDark={isDark} />
    <LinkScribble color={scribbleColor} primary={primary} />
  </Box>
);
