import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisContactPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisContactPage {
        nodes {
          entry {
            form {
              headline {
                html
              }
            }
          }
          translations {
            en {
              form {
                headline {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
