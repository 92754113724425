import React from 'react';

// External Components
import { FlexWrapper } from '@thepuzzlers/pieces';

// Local Components
import { AspectRatioImageContainer } from 'components';

export const Awards = ({ data, sx, imageSx }) => (
  <FlexWrapper
    className="header__header__awards-logos"
    sx={{
      alignSelf: 'start',
      justifySelf: 'end',
      gridColumn: ['4/13', '6/13', '12/25', '15/25', '15/25', '15/25'],
      justifyContent: 'space-between',
      mt: ['3.2rem', '3.2rem', '4rem', '1.2rem', '2.4rem', '3.2rem'],
      width: '100%',
      ...sx
    }}>
    {data.map((award, index) => {
      const { width, height } = award.src.childImageSharp?.gatsbyImageData;
      return (
        <AspectRatioImageContainer
          key={index}
          className="gatsby-image"
          src={award.src}
          alt={award.alt}
          aspectRatios={width / height}
          sx={{
            height: [
              '6.9rem',
              '8.4rem',
              '10.1rem',
              '8rem',
              '11.4rem',
              '12.2rem'
            ],
            ...imageSx
          }}
        />
      );
    })}
  </FlexWrapper>
);
