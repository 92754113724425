import React from 'react';

// External Components
import { Heading } from '@thepuzzlers/pieces';

export const UnderlinedHeading = ({
  data,
  as,
  sx,
  spanSx,
  underlineSx,
  ...props
}) => (
  <Heading
    as={as}
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      fontFamily: 'primary.normal',
      ...sx,
      '& > span': {
        display: 'inline-block',
        fontFamily: 'primary.italic',
        position: 'relative',
        zIndex: 1,
        ...spanSx,
        '&::before': {
          bg: 'secondary',
          content: '""',
          display: 'block',
          position: 'absolute',
          height: '45%',
          width: '100%',
          zIndex: -1,
          bottom: 0,
          ...underlineSx
        }
      }
    }}
    {...props}
  />
);
