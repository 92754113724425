import React from 'react';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const useScrollTriggerRefresh = () => {
  React.useEffect(() => {
    const targetNode = document.body;
    const handleResize = () => {
      // it refresh for both height and width resize
      ScrollTrigger.refresh();
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(targetNode);
  }, []);
};
