import React from 'react';

// External Components
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

export const RegularLink = ({ to, text, primary, isDark, sx }) => (
  <NavigationLink
    to={to}
    variant={primary ? 'hyperlink.primary' : 'hyperlink.secondary'}
    sx={{
      bg: isDark ? 'primary' : 'secondary',
      color: isDark ? 'light' : 'text',
      ...sx
    }}>
    {text}
  </NavigationLink>
);
